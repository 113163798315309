export const widgetPromotionsAPI = {
    baseURL: {
        v1: process.env.REACT_APP_WIDGET_PROMOTIONS_API_URL,
        v2: process.env.REACT_APP_WIDGET_PROMOTIONS_API_URL_V2
    },
    endPoints: {
        promotions: {
            getPromotions: {
                v1: '/promotions/get-promotions',
                v2: '/promotions'
            }
        }
    }
};

export const giftly = {
    baseURL: process.env.REACT_APP_GIFTLY_URL,
    endPoints: {
        marketplace: '/marketplace',
        product: (productId) => `/marketplace/${productId}`
    }
};
