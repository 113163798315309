import React from 'react';
import { useAppContext } from './hooks/index';
import { getData } from './hooks/stores/data';
import Spinner from './shared/components/spinner';
import getParam from './shared/utils/getParam';

const App = ({ children }) => {
    const appContext = useAppContext();
    const promotions = getData('promotions');

    const width = Number(getParam('width', 300));
    const height = Number(getParam('height', 250));

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/interactive-supports-focus
        <div
            role='button'
            onClick={(event) => appContext.handleClickAnyWhere(event)}
            onMouseOver={() => appContext.handleUserEvent('widget-window', false, getData('uniqBrands'))}
            onFocus={() => appContext.handleUserEvent('widget-window', false, getData('uniqBrands'))}
            onMouseMove={(event) => appContext.handleMouseMove('widget-window', event.target.className)}
            onMouseLeave={() => {
                appContext.handleUserEvent('widget-window', true);
                appContext.sendAllMouseOver();
            }}
            onTouchMove={appContext.throttledTouchMove}
            onTouchStart={appContext.handleTouchStart}
            onTouchEnd={appContext.handleTouchEnd}
            className='wrapper'
            style={{ width, height }}
        >
            {appContext.isSpinner ? <Spinner /> : promotions?.length ? children : <Spinner />}
        </div>
    );
};

export default App;
