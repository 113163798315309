import React from 'react';

const Unit300x250 = React.lazy(() => import('../../components/300x250'));
const Unit300x600 = React.lazy(() => import('../../components/300x600'));
const Unit320x100 = React.lazy(() => import('../../components/320x100'));
const Unit336x280 = React.lazy(() => import('../../components/336x280'));
const Unit970x250 = React.lazy(() => import('../../components/970x250'));
const Unit728x90 = React.lazy(() => import('../../components/728x90'));
const Unit320x50 = React.lazy(() => import('../../components/320x50'));
const Unit160x600 = React.lazy(() => import('../../components/160x600'));

export const UNITS = {
    '300x250': Unit300x250,
    '336x280': Unit336x280,
    '320x100': Unit320x100,
    '300x600': Unit300x600,
    '970x250': Unit970x250,
    '728x90': Unit728x90,
    '320x50': Unit320x50,
    '160x600': Unit160x600
};
