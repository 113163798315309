import React from 'react';
import { getData } from '../../hooks/stores/data';
import Spinner from '../../shared/components/spinner';
import { getNeededUnit } from '../../shared/utils';

const Units = () => {
    const NeededUnit = getNeededUnit(getData('width'), getData('height'));
    const hasPromotions = getData('promotions')?.length;

    if (NeededUnit) return hasPromotions ? <NeededUnit /> : <Spinner />;

    return <div>Such unit width/height not supported</div>;
};

export default Units;
