import * as get from 'lodash/get';
import { giftly } from '../../config/api';
import { defaultCreativeSettings } from '../constants/constants';

export const prepareBrandedData = (appState) => {
    const utmSearchParams = new URLSearchParams(
        'utm_source=klickly&utm_medium=marketing&utm_campaign=general&utm_term=general'
    );
    const domain = appState?.promotions?.[0]?.connectedDomain || null;
    const isBrandUnit = appState?.brandId || null;

    const {
        fontName = defaultCreativeSettings.fontName,
        primaryColor = defaultCreativeSettings.primaryColor,
        secondaryColor = defaultCreativeSettings.secondaryColor,
        offerMessage = defaultCreativeSettings.offerMessage,
        logoImage = null,
        coverImages = {}
    } = appState.viewSettings || {};

    let logoSrc = defaultCreativeSettings.logoLocation;
    let linkToOpenOnClick = giftly.baseURL;

    if (isBrandUnit) {
        const promotionSearchParams = new URLSearchParams(appState?.promotions?.[0]?.utmParams || '');

        if (promotionSearchParams.has('utm_source')) {
            utmSearchParams.set('utm_source', promotionSearchParams.get('utm_source'));
        }

        if (promotionSearchParams.has('utm_medium')) {
            utmSearchParams.set('utm_medium', promotionSearchParams.get('utm_medium'));
        }

        logoSrc = get(logoImage, 'Location', null);
        linkToOpenOnClick = `https://${domain}?${utmSearchParams.toString()}`;
    }

    return {
        isBrandUnit,
        domain,
        linkToOpenOnClick,
        logoSrc,
        offerMessage,
        fontStyles: { fontFamily: fontName },
        buttonStyles: { backgroundColor: primaryColor, fontFamily: fontName },
        messageStyles: { backgroundColor: secondaryColor, color: primaryColor, fontFamily: fontName },
        colors: { primaryColor, secondaryColor },
        coverImages
    };
};
