export const klicklyBrandingClassNamesMap = [
    'widgetW160__branding-wrapper',
    'widgetW160__branding-main',
    'widgetW160__branding-hover',
    'widget300x250__branding-main',
    'widget300x600__branding-main',
    'widget320x90__branding-wrapper',
    'widget320x90__branding-main',
    'widget320x90__branding-hover',
    'widget970__branding-wrapper',
    'widget970__branding-main',
    'widgetH50__branding-wrapper',
    'widgetH50__branding-main',
    'widgetH50__branding-hover',
    'widgetH90__branding-wrapper',
    'widgetH90__branding-main'
];
