import * as find from 'lodash/find';
import * as get from 'lodash/get';
import { roundToLocaleString } from './static';

export const preparePromotions = (promotions) => {
    return promotions.map((promotion) => {
        const imagesExist = Boolean(promotion.images.length);
        const variantsExist = Boolean(promotion.variants.length);
        const heroImage = imagesExist
            ? find(promotion.images, { externalId: Number(promotion.heroImageExternalId) })
            : null;
        const imageSRC = imagesExist ? (heroImage ? heroImage.src : promotion.images[0].src) : '//placehold.it/200x120';

        const price = variantsExist ? get(promotion, 'variants[0].price') : null;
        const originalPrice = variantsExist ? get(promotion, 'variants[0].originalPrice') : null;
        const isShowOriginalPrice = Number(originalPrice) > Number(price);

        return {
            id: promotion._id,
            src: imageSRC,
            title: promotion.title,
            storeType: promotion.storeType,
            price: price ? roundToLocaleString(price) : null,
            originalPrice: originalPrice ? roundToLocaleString(originalPrice) : null,
            isShowOriginalPrice,
            brandName: promotion.brandName,
            images: imagesExist ? promotion.images : [{ src: '//placehold.it/200x120' }],
            description: promotion.description,
            originalObject: promotion,
            creativeSettings: promotion.creativeSettings
        };
    });
};
