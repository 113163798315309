import React from 'react';
import { createRoot } from 'react-dom/client';
import { AppContextProvider } from './hooks/index';
import Widget from './widget';
import './styles/index.scss';

const container = document.getElementById('klickly-widget-root');
const root = createRoot(container);

root.render(
    <AppContextProvider>
        <Widget />
    </AppContextProvider>
);
