import { UNITS, widgetSizeCountProductsInUnit } from '../constants';

export const getNeededUnit = (width, height) => {
    return UNITS[`${width}x${height}`];
};

export const getWidgetSizeCountProductsInUnit = (width, height) => {
    const unit = `${width}x${height}`;
    return widgetSizeCountProductsInUnit[unit] || 6;
};
