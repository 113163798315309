export class SessionStorage {
    static get(key) {
        try {
            return sessionStorage.getItem(key);
        } catch (e) {
            return undefined;
        }
    }

    static set(key, value) {
        try {
            sessionStorage.setItem(key, value);
            return SessionStorage.get(key) === value;
        } catch (e) {
            return false;
        }
    }

    static remove(key) {
        try {
            sessionStorage.removeItem(key);
        } catch (e) {
            return false;
        }
    }
}
