import { Cookie } from './Cookie';
import { LocalStorage } from './LocalStorage';
import { SessionStorage } from './SessionStorage';

const storages = [Cookie, LocalStorage, SessionStorage];

export class BrowserStorage {
    static get(key) {
        let value;

        for (let i = 0; i < storages.length; i++) {
            value = storages[i].get(key);

            if (value) {
                break;
            }
        }

        return value;
    }

    static set(key, value) {
        for (let i = 0; i < storages.length; i++) {
            storages[i].set(key, value);
        }
    }

    static remove(key) {
        for (let i = 0; i < storages.length; i++) {
            storages[i].remove(key);
        }
    }
}

BrowserStorage.Cookie = Cookie;
BrowserStorage.LocalStorage = LocalStorage;
BrowserStorage.SessionStorage = SessionStorage;
