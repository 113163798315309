import * as isArray from 'lodash/isArray';

export class TrackCustomEvent {
    startTime = null;
    eventLength = 0;
    element = '';

    constructor(trackData) {
        this.resume();
        this.trackData = trackData;
    }

    addBrandedLogoElem(element) {
        this.element = element;
    }

    resume() {
        if (this.startTime === null) {
            this.startTime = new Date();
        }
    }

    pause() {
        if (this.startTime) {
            // time spent in mouseover/touchmove state in ms
            const endTime = new Date();
            this.eventLength += endTime.getTime() - this.startTime.getTime();

            this.startTime = null;
        }
    }

    end() {
        this.pause();

        if (isArray(this.trackData)) {
            return this.trackData.map((data) => {
                return { ...data, eventLength: this.eventLength, eventElement: this.element };
            });
        }

        return { ...this.trackData, eventLength: this.eventLength, eventElement: this.element };
    }
}
