export class TouchData {
    constructor() {
        this.reset();
    }

    reset() {
        this.firstTouchEvent = null;
        this.timestampStart = 0;
        this.timestampEnd = 0;
        this.startX = 0;
        this.startY = 0;
        this.endX = 0;
        this.endY = 0;
    }

    setFirstTouchEvent(event) {
        this.firstTouchEvent = event;
    }

    setStartData(timestamp, touch) {
        this.timestampStart = timestamp;
        this.startX = touch.clientX;
        this.startY = touch.clientY;
    }

    setEndData(timestamp, touch) {
        this.timestampEnd = timestamp;
        this.endX = touch.clientX;
        this.endY = touch.clientY;
    }

    get timestampVector() {
        return this.timestampEnd - this.timestampStart;
    }

    get moveVector() {
        return {
            x: this.endX - this.startX,
            y: this.endY - this.startY
        };
    }

    getTouchedProductId(event) {
        const touch = event.changedTouches[0];
        const touchX = touch.clientX;
        const touchY = touch.clientY;

        const element = document.elementFromPoint(touchX, touchY);

        if (!element) return false;

        const touchedProduct = element.closest('[data-product-id]');

        if (!touchedProduct) return false;

        return touchedProduct.getAttribute('data-product-id');
    }

    buildTouchData(touch, event) {
        return {
            clientX: touch.clientX,
            clientY: touch.clientY,
            timestamp: event.timeStamp,
            touchPressure: touch.force,
            radiusX: touch.radiusX,
            radiusY: touch.radiusY
        };
    }
}
