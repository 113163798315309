const parseResponse = (xhr) => {
    try {
        return JSON.parse(xhr.response);
    } catch (e) {
        return xhr.response;
    }
};

export const xhrInstance = ({ url, params = null, data = {}, method = 'GET' }) => {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    const xhrUrl = new URL(url);
    if (params) {
        for (const key of Object.keys(params)) {
            xhrUrl.searchParams.set(key, params[key]);
        }
    }

    xhr.open(method, xhrUrl.toString(), true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(data));

    return new Promise((resolve, reject) => {
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                if (xhr.status >= 200 && xhr.status < 300) {
                    resolve({ data: parseResponse(xhr) });
                } else {
                    reject(xhr.statusText);
                }
            }
        };
    });
};

export const xhrGet = async (url, params) => {
    try {
        const { data } = await xhrInstance({
            url,
            params
        });
        return data;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
};

export const xhrPost = async (urlReq, requestData) => {
    try {
        const { data } = await xhrInstance({
            url: urlReq,
            data: requestData,
            method: 'POST'
        });
        return data;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
};
