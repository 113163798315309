import React, { createContext, useContext } from 'react';
import { useData } from './app';

const AppContent = createContext(null);

export function AppContextProvider({ children }) {
    return <AppContent.Provider value={useData()}>{children}</AppContent.Provider>;
}

export function useAppContext() {
    const context = useContext(AppContent);
    if (!context) {
        throw new Error('useAppContext should be within a AppContextProvider');
    }

    return context;
}
