const { REACT_APP_LIVERAMP_PIXEL_ID } = process.env;

export const load = (data) => {
    if (!REACT_APP_LIVERAMP_PIXEL_ID) {
        return;
    }

    const img = new Image();
    const cparams = btoa(JSON.stringify(data));
    img.src = `https://id.rlcdn.com/${REACT_APP_LIVERAMP_PIXEL_ID}.gif?cparams=${cparams}`;
};
