// Units constants
import Img from '../../images/giftly-logo.svg';

export const defaultCreativeSettings = {
    fontName: 'Arial',
    primaryColor: '#04080F',
    secondaryColor: '#FFFFFF',
    offerMessage: 'EXCLUSIVE REWARDS',
    logoLocation: Img
};
