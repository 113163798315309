import { getURLParam } from './static';

const windowUrl = window.location.search.slice();
const klicklyParams = window.KlicklyParams;

const getParam = (field, defaultValue = null) => {
    if (klicklyParams && klicklyParams[field]) {
        return klicklyParams[field];
    }

    return getURLParam(field, windowUrl) || defaultValue;
};

export default getParam;
